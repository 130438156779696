






































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { promoterStoreActions, promoterStoreGetters } from '@/store/promoter.store';
import Promoter from '@/models/Promoter';
import moment from 'moment';

const PromoterStore = namespace('promoter');

@Component({
    components: {
        PromoterTableComponent: () => import(
            /* webpackChunkName: "PromoterTableComponent" */
            '@/components/Promoters/PromotersTable.component.vue'),
        EditPromoterComponent: () => import(
            /* webpackChunkName: "EditPromoterComponent" */
            '@/components/Promoters/EditPromoter.component.vue'
        ),
        PromoterStatisticsListComponent: () => import(
            /* webpackChunkName: "PromoterStatisticsListComponent" */
            '@/components/Promoters/PromoterStatisticsList.component.vue'
        )
    }
})
export default class PromoterView extends Vue {
    @PromoterStore.Action(promoterStoreActions.GET)
    public loadAllPromoters!: (payload: { from: string, to: string }) => Promise<Promoter[]>;

    @PromoterStore.Action(promoterStoreActions.DELETE)
    public deletePromoter!: (id: string) => Promise<void>;

    @PromoterStore.Getter(promoterStoreGetters.PROMOTERS)
    public promoters!: Promoter[];

    public startMenu: boolean = false;
    public endMenu: boolean = false;
    public startDate: string | null = null;
    public endDate: string | null = null;

    public searchValue: string = '';
    public showCreationDialog: boolean = false;
    public showDeleteDialog: boolean = false;
    public showStatusDialog: boolean = false;
    public showStatisticsDialog: boolean = false;
    private isLoading: boolean = false;
    private selectedPromoter: Promoter | null = null;

    public async created() {
        const today = moment(new Date());
        this.endDate = today.format('yyyy-MM-DD');
        today.set({ date: 1 });
        this.startDate = today.format('yyyy-MM-DD');

        try {
            this.isLoading = true;
            await this.loadAllPromoters({ from: this.startDate, to: this.endDate });
        } finally {
            this.isLoading = false;
        }
    }

    private async loadPromoters() {
        await this.loadAllPromoters({ from: this.startDate!, to: this.endDate! });
    }

    public async closeDialog(reload: boolean) {
        this.showCreationDialog = false;
        this.showDeleteDialog = false;
        this.showStatusDialog = false;
        this.showStatisticsDialog = false;
        this.selectedPromoter = null;
        if (reload) {
            await this.loadPromoters();
        }
    }

    public async onStatisticsClicked(item: Promoter) {
        this.selectedPromoter = item;
        this.showStatisticsDialog = true;
    }

    public async onEditPromoter(item: Promoter) {
        this.selectedPromoter = item;
        this.showCreationDialog = true;
    }

    public onDeletePromoter(item: Promoter) {
        this.selectedPromoter = item;
        this.showDeleteDialog = true;
    }

    public async onDeletePromoterConfirmed() {
        if (!this.selectedPromoter) return;
        try {
            this.isLoading = true;
            await this.deletePromoter(this.selectedPromoter.id);
            this.showDeleteDialog = false;
            await this.loadPromoters();
            this.$notifySuccessSimplified('PROMOTER.DELETE.SUCCESS');
        } catch (e) {
            this.$notifyErrorSimplified('PROMOTER.DELETE.ERROR');
        } finally {
            this.isLoading = false;
        }
    }
}
